import React from "react";
import { Link } from "react-router-dom";
function Footer() {
  return (
    <>
      <footer className="text-center text-lg-start bg-dark text-white">
        <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
          <div className="me-5 d-none d-lg-block">
            <span>Get connected with us on social networks:</span>
          </div>
          <div>
            <Link className="me-4 link-secondary">
              <i className="fab fa-facebook-f"></i>
            </Link>
            <Link className="me-4 link-secondary">
              <i className="fab fa-twitter"></i>
            </Link>
            <Link className="me-4 link-secondary">
              <i className="fab fa-google"></i>
            </Link>
            <Link className="me-4 link-secondary">
              <i className="fab fa-instagram"></i>
            </Link>
            <Link className="me-4 link-secondary">
              <i className="fab fa-linkedin"></i>
            </Link>
            <Link className="me-4 link-secondary">
              <i className="fab fa-github"></i>
            </Link>
          </div>
        </section>

        <section className="">
          <div className="container text-center text-md-start mt-5">
            <div className="row mt-3">
              <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">
                  <i className="fas fa-gem me-3 text-secondary"></i>
                  uspharma company
                </h6>
                <p>
                  33 Obour building, Salah Salem street. Nasr city, Cairo, Egypt
                </p>
                <h6>Vitality Way Est</h6>
                <p>
                  Om al moamneen Habiba, street Naseem District, Jeddah, Saudi
                  Arabia
                </p>
              </div>

              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Pages</h6>
                <p>
                  <Link className="nav-link" to="/">
                    Home
                  </Link>
                </p>
                <p>
                  <Link className="nav-link" to="/about">
                    About
                  </Link>
                </p>
                <p>
                  <Link className="nav-link" to="/team">
                    Team
                  </Link>
                </p>
                <p>
                  <Link className="nav-link" to="/services">
                    Services
                  </Link>
                </p>
              </div>

              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Pages</h6>
                <p>
                  <Link className="nav-link" to="/brands">
                    Brands
                  </Link>
                </p>
                <p>
                  <Link className="nav-link" to="/partners">
                    Partners
                  </Link>
                </p>
                <p>
                  <Link className="nav-link" to="/facilities">
                    Facilities
                  </Link>
                </p>
                <p>
                  <Link className="nav-link" to="/contact">
                    Contact us
                  </Link>
                </p>
              </div>

              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
                <p>
                  <i className="fas fa-envelope me-3 text-secondary"></i>
                  info@uspharma.co
                </p>
              </div>
            </div>
          </div>
        </section>

        <div
          className="text-center p-4"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.025)" }}
        >
          <Link className="nav-link" to="/">
            all right reserved to uspharma.co &copy; 2023-2024
          </Link>
        </div>
      </footer>
    </>
  );
}

export default Footer;
