import React from "react";
import Facility from "../assets/facility.png";
function Facilities() {
  return (
    <div className="row mt-5 p-5" style={{ fontFamily: "Caprasimo" }}>
      <h3
        className="text-primary"
        style={{
          fontFamily: "Caprasimo",
          fontStyle: "oblique",
          textAlign: "center",
        }}
      >
        Facilties
      </h3>

      <div className="row container shadow-lg border p-3 m-3 d-flex justify-content-center">
        {/* <div className="col-4"></div> */}
        <div className="col-3">
          <div className="card shadow-lg p-2 m-2">
            <h5 className="text-primary card-body">
              Well Safe shipping from Store and excellent packing Quickly
            </h5>
          </div>

          {/*  */}

          <div className="card shadow-lg p-2 m-2">
            <h5 className="text-primary card-body">
              Fast Delivery by many sizes vehicles
            </h5>
          </div>
          {/*  */}
        </div>

        <div className="col-6">
          <img src={Facility} alt="..." style={{ width: "100%" }} />
        </div>
        {/* <div className="col-4"></div> */}

        <div className="col-3">
          <div className="card shadow-lg p-2 m-2">
            <h5 className="text-primary card-body">
              Easily Process for openning Account for Customers
            </h5>
          </div>

          <div className="card shadow-lg p-2 m-2">
            <h5 className="text-primary card-body">
              Many attractive Promotions for customers
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Facilities;
