import TeamImg from "../assets/our-team.png";
import ManageTeam from "../assets/manage-team.png";
import StoreTeam from "../assets/store-team.png";
import RegulaTeam from "../assets/regula-team.png";
import SalesTeam from "../assets/Sales-team.png";

function Team() {
  return (
    <div
      className="row mt-5 p-5"
      style={{ fontFamily: "Caprasimo", fontStyle: "oblique" }}
    >
      <div className="col-sm-2">
        <div className="row">
          <h3 className="text-primary border shadow">Our Team</h3>
          <img
            src={TeamImg}
            alt="team"
            style={{ width: "200px", height: "200px" }}
            className="img-thumbnail"
          />
        </div>
      </div>

      <div className="col-sm-2 m-3 p-3 card justify-content-center">
        <div className="row shadow-lg rounded card-body">
          <img src={ManageTeam} className="card-img-top rounded" alt="..." />
          <h5
            className="text-danger card-title"
            style={{
              fontFamily: "Caprasimo",
              fontStyle: "oblique",
              textAlign: "center",
            }}
          >
            Management Team
          </h5>

          <p>
            US-PHARMA has a group of high-level associates hired by a business
            owner to take on essential responsibilities within US-PHARMA to
            acheive targets in professional manner maintaining business growth .
          </p>
        </div>
      </div>

      <div className="col-sm-2 m-3 p-3 card justify-content-center">
        <div className="row shadow-lg rounded card-body">
          <img src={StoreTeam} className="card-img-top rounded" alt="..." />

          <h5
            className="text-danger card-title"
            style={{
              fontFamily: "Caprasimo",
              fontStyle: "oblique",
              textAlign: "center",
            }}
          >
            Store Team
          </h5>
          <p>
            Experienced store team who are working in a group and facilitates
            orders within our store across several products, including food
            Supplements,Medicines, retail maintaining relation with other
            department.
          </p>
        </div>
      </div>
      {/*  */}
      <div className="col-sm-2 m-3 p-3 card justify-content-center">
        <div className="row shadow-lg rounded card-body">
          <img src={RegulaTeam} className="card-img-top rounded" alt="..." />
          <h5
            className="text-danger card-title"
            style={{
              fontFamily: "Caprasimo",
              fontStyle: "oblique",
              textAlign: "center",
            }}
          >
            Regulatory Team
          </h5>
          <p>
            A high-level of Experienced Employees that taking care of products
            to inssure products Quality and taking care of products registration
            within Governments organizations maintaining testing roles and
            regulations.
          </p>
        </div>
      </div>
      {/*  */}
      <div className="col-sm-2 m-3 p-3 card justify-content-center">
        <div className="row shadow-lg rounded card-body">
          <img src={SalesTeam} className="card-img-top rounded" alt="..." />

          <h5
            className="text-danger card-title"
            style={{
              fontFamily: "Caprasimo",
              fontStyle: "oblique",
              textAlign: "center",
            }}
          >
            Sales Team
          </h5>
          <p>
            US-Pharma Sales Team is doing their best to cover and satisfy the
            Company Customers needs and requirements where they are.
          </p>
        </div>
      </div>

      {/*  */}
    </div>
  );
}

export default Team;
