import React from "react";
import Partner1 from "../assets/partner1.png";
import Partner2 from "../assets/partner2.png";
import Partner3 from "../assets/partner3.png";
import Partner4 from "../assets/partner4.png";

function Partners() {
  return (
    <div className="row mt-5 p-5" style={{ fontFamily: "Caprasimo" }}>
      <div className="row">
        <h3
          className="text-primary"
          style={{
            fontFamily: "Caprasimo",
            fontStyle: "oblique",
            textAlign: "center",
          }}
        >
          US-Pharma Partners
        </h3>
      </div>

      <div
        className="row shadow-lg p-3 m-3"
        style={{
          fontFamily: "Caprasimo",
          fontStyle: "oblique",
          textAlign: "center",
        }}
      >
        <div className="row">
          <div
            className="row bg-dark p-2 m-3 shadow-lg "
            style={{
              fontFamily: "Caprasimo",
              fontStyle: "oblique",
              textAlign: "center",
            }}
          >
            <h5 className="text-warning">
              <span className="text-white">US_PHARMA </span>is an important
              business player in medicines Supplier in Egypt Since
              <span className="text-white"> 25 years </span>
              deals with many Brand Suppliers as per international high level
              standard.
            </h5>
          </div>
        </div>

        <div className="d-flex row container justify-content-center  d-flex">
          {/* 1 card */}
          <div className="flex col-auto m-1 shadow-lg rounded bg-dark text-white">
            <div>
              <img
                src={Partner1}
                alt=".."
                className="flex justify-content-center p-2"
                style={{ width: "200px", height: "80px" }}
              />

              <h5 className="font-bold text-warning">Atisav Pharma</h5>
              <h6 className="">Atisav pharma Portugal</h6>
            </div>
          </div>

          {/* 2 Card */}

          <div className="flex col-auto m-1 shadow-lg rounded bg-dark text-white">
            <div>
              <img
                src={Partner2}
                alt=".."
                className="flex justify-content-center p-2"
                style={{ width: "200px", height: "80px" }}
              />

              <h5 className="font-bold text-warning">Vitality Way</h5>
              <h6 className="">Vitality Way Saudi Arabia</h6>
            </div>
          </div>

          {/* Card 3 */}

          <div className="flex col-auto m-1 shadow-lg rounded bg-dark text-white">
            <div>
              <img
                src={Partner3}
                alt=".."
                className="flex justify-content-center p-2"
                style={{ width: "200px", height: "80px" }}
              />

              <h5 className="font-bold text-warning">Cloverty</h5>
              <h6 className="">Cloverty Spain</h6>
            </div>
          </div>

          {/* card 4 */}

          <div className="flex col-auto m-1 shadow-lg rounded bg-dark text-white">
            <div>
              <img
                src={Partner4}
                alt=".."
                className="justify-content-center p-2"
                style={{ width: "120px", height: "80px" }}
              />

              <h5 className="font-bold text-warning">Smartec</h5>
              <h6 className="space-between">pharmaceuticals/cosmetics Egypt</h6>
            </div>
          </div>
        </div>

        {/* <div className="col-sm-3">
          <div className="card" style={{ width: "18rem" }}>
            <img
              src={Partner1}
              class="card-img-top img-thumbnail p-2 m-2 shadow-lg"
              alt="..."
            />
            <div className="card-body">
              <h5 className="card-title text-danger p-1 m-1 shadow-lg">
                ATISAV Pharma
              </h5>
              <p className="card-text">Atisav pharma Portugal</p>
            </div>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="card" style={{ width: "18rem" }}>
            <img
              src={Partner2}
              class="card-img-top img-thumbnail p-2 m-2 shadow-lg"
              alt="..."
            />
            <div className="card-body">
              <h5 className="card-title text-danger p-3 m-3 shadow-lg">
                Vitality Way
              </h5>
              <p className="card-text">Vitality Way Saudi Arabia</p>
            </div>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="card" style={{ width: "18rem" }}>
            <img
              src={Partner3}
              class="card-img-top img-thumbnail p-2 m-2 shadow-lg"
              alt="..."
            />
            <div className="card-body">
              <h5 className="card-title text-danger p-1 m-1 shadow-lg">
                Cloverty
              </h5>
              <p className="card-text">Cloverty Spain</p>
            </div>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="card" style={{ width: "18rem" }}>
            <img
              src={Partner4}
              class="card-img-top img-thumbnail p-2 m-2 shadow-lg"
              alt="..."
            />
            <div className="card-body">
              <h5 className="card-title text-danger p-1 m-1 shadow-lg">
                Smartec{" "}
              </h5>
              <p className="card-text">
                smartec pharmaceuticals and cosmetics Egypt
              </p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Partners;
