import React from "react";
import ContactImg from "../assets/contact.png";

function Contact() {
  return (
    <div
      className="row mt-5 p-5"
      style={{
        fontFamily: "Caprasimo",
        fontStyle: "oblique",
      }}
    >
      <div className="row">
        <h3
          className="text-primary"
          style={{
            fontFamily: "Caprasimo",
            fontStyle: "oblique",
            textAlign: "center",
          }}
        >
          US-Pharma is welcoming all Question:
        </h3>
      </div>

      <form action="https://formsubmit.co/info@uspharma.co" method="POST">
        <div className="row shadow-lg p-3 m-3 bg-light">
          <div className="col-sm-5">
            <img src={ContactImg} alt="..." style={{ width: "550px" }} />
          </div>
          <div
            className="col-sm-4 text-light shadow-lg p-2 m-2"
            style={{ backgroundColor: "#0070C0" }}
          >
            <h6>Message us</h6>

            <div className="row">
              <label className="form-label">
                Name :
                <input
                  type="text"
                  className="form-control form-control-sm mb-2 w-75"
                  name="name"
                  placeholder="Full Name"
                />
              </label>
            </div>

            <div className="row">
              <label className="form-label">
                Email :
                <input
                  type="email"
                  className="form-control form-control-sm mb-2 w-75"
                  name="email"
                  placeholder="Email"
                />
              </label>
            </div>

            <div className="row">
              <label className="form-label">
                Phone :
                <input
                  type="text"
                  className="form-control form-control-sm mb-2 w-75"
                  name="Phone"
                  placeholder="Phone"
                />
              </label>
            </div>

            <div className="row">
              <label className="form-label">
                Name :
                <textarea
                  name="message"
                  id=""
                  cols="30"
                  rows="10"
                  className="form-control form-control-sm w-75"
                  placeholder="Message"
                />
              </label>
            </div>

            <div className="row p-1 m-1 w-75">
              <button type="submit" className="btn btn-dark">
                Send
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Contact;
