import { Hero } from "../components";
import React from "react";

function Home() {
  return (
    <div className="row mt-5 p-5">
      <div className="col-sm-3">
        <div className="row">
          <p>
            <span className="text-danger h6 p-1">We are </span>
            an Egyptian based pharmaceutical company fortified with 25 years of
            pharma experience.
          </p>
          <p>
            <span className="text-danger h6 p-1">Specialized</span> in
            Distribution , Sales and Marketing of Pharmaceutical and Medical
            Products all over Egypt and Gulf Region since 2013
          </p>

          <p>
            <span className="text-danger h6 p-1">Distribute</span> Products to a
            wide Network in Healthcare Sectors (Retail stores & Pharmacies &
            Hospitals and Polyclinics).
          </p>

          <p>
            <span className="text-danger h6 p-1">Work</span> in Egypt, Saudi
            Arabia and Gulf Region With a professional partners.
          </p>
        </div>
      </div>
      <div className="col-sm-6">
        <Hero />
      </div>
      <div className="col-sm-3">
        <div className="row shadow-lg p-1 mb-5 m-1 bg-danger text-danger rounded">
          <h1
            className="text-warning"
            style={{
              fontFamily: "Caprasimo",
              fontStyle: "oblique",
              textAlign: "center",
            }}
          >
            Vission
          </h1>
          <div className="row text-white">
            <h2
              style={{
                fontFamily: "Caprasimo",
                fontStyle: "oblique",
                textAlign: "center",
              }}
            >
              To be the successful partner of choice for our customers
            </h2>
          </div>
        </div>

        <div
          className="row shadow-lg p-1 mb-5 m-1 text-danger rounded"
          style={{ backgroundColor: "#54216b" }}
        >
          <h1
            className="text-warning"
            style={{
              fontFamily: "Caprasimo",
              fontStyle: "oblique",
              textAlign: "center",
            }}
          >
            Mission
          </h1>
          <div className="row text-white">
            <h2
              style={{
                fontFamily: "Caprasimo",
                fontStyle: "oblique",
                textAlign: "center",
              }}
            >
              We are dedicated to help people live healthier, and improve their
              quality of life
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
