import "./App.css";
import { Routes, Route } from "react-router-dom";
import {
  About,
  Home,
  Brands,
  Contact,
  Facilities,
  Partners,
  Services,
  Team,
} from "./pages";
import { Footer, Navbar } from "./components";

function App() {
  return (
    <>
      <header>
        <div className="row fixed-top shadow-lg p-1 mb-5 rounded bg-body-tertiary">
          <Navbar />
        </div>
      </header>
      <main>
        <div className="row">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/brands" element={<Brands />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/facilities" element={<Facilities />} />
            <Route path="/partners" element={<Partners />} />
            <Route path="/services" element={<Services />} />
            <Route path="/team" element={<Team />} />
          </Routes>
        </div>
      </main>

      <footer>
        <Footer />
      </footer>
    </>
  );
}

export default App;
