import React from "react";
import Brand1 from "../assets/brand1.png";
import Brand2 from "../assets/brand2.png";
import Brand3 from "../assets/brand3.png";

function Brands() {
  return (
    <div className="row mt-5 p-5" style={{ fontFamily: "Caprasimo" }}>
      <h3
        className="text-primary"
        style={{
          fontFamily: "Caprasimo",
          fontStyle: "oblique",
          textAlign: "center",
        }}
      >
        Brands
      </h3>

      <div
        class="alert alert-primary d-flex justify-content-center"
        style={{ color: "#7430f9", fontSize: "30px" }}
      >
        High Quality International Brands you can find with{" "}
        <span className="text-danger" style={{ paddingLeft: "3px" }}>
          {" "}
          us-pharma
        </span>
      </div>

      <div className="row p-2 m-2">
        <div className="col-sm-4 shadow-lg p-1 rounded">
          <img
            src={Brand1}
            alt="..."
            style={{ width: "450px" }}
            className="img-thumbnail"
          />
        </div>
        <div className="col-sm-4 shadow-lg p-1 rounded">
          <img
            src={Brand2}
            alt="..."
            style={{ width: "450px" }}
            className="img-thumbnail"
          />
        </div>
        <div className="col-sm-4 shadow-lg p-1 rounded">
          <img
            src={Brand3}
            alt="..."
            style={{ width: "450px" }}
            className="img-thumbnail"
          />
        </div>
      </div>
    </div>
  );
}

export default Brands;
