import React from "react";
import Hero1 from "../assets/hero1.png";
import Hero2 from "../assets/hero2.png";
import Hero3 from "../assets/hero3.png";
function Hero() {
  return (
    // <div className="container row shadow lg- p-4 m-4 bg-light">
    //   <div className="col-2">...</div>
    //   <div className="col-8">
    <div id="carouselExampleIndicators" className="carousel slide">
      <div className="carousel-indicators">
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="0"
          className="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
      </div>
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img
            src={Hero1}
            className="d-flex d-block w-100"
            alt="..."
            style={{ width: "1000px", height: "500px" }}
          />
        </div>
        <div className="carousel-item">
          <img
            src={Hero2}
            className="d-flex d-block w-100"
            alt="..."
            style={{ width: "1000px", height: "500px" }}
          />
        </div>
        <div className="carousel-item">
          <img
            src={Hero3}
            className="d-flex d-block w-100"
            alt="..."
            style={{ width: "1000px", height: "500px" }}
          />
        </div>
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
}

export default Hero;
