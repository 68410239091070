import { Link } from "react-router-dom";
import Logo from "../assets/logo192.png";

function Navbar() {
  return (
    <nav
      className="navbar navbar-expand-lg  text-white"
      style={{ backgroundColor: "#7430f9" }}
    >
      <div className="container-fluid">
        <Link className="navbar-brand text-white" to="/">
          US Pharma
        </Link>
        <Link className="nav-link text-white" to="/">
          <img
            src={Logo}
            alt="logo"
            style={{ width: "20%" }}
            className="img-thumbnail"
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          // aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarNav"
        >
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-link text-white">
              <Link
                className="nav-link text-white"
                // data-target=".navbar-collapse"
                aria-current="page"
                to="/"
              >
                Home
              </Link>
            </li>
            <li className="nav-link text-white">
              <Link
                className="nav-link text-white"
                aria-current="page"
                to="/about"
              >
                About
              </Link>
            </li>
            <li className="nav-link text-white">
              <Link
                className="nav-link text-white"
                aria-current="page"
                to="/team"
              >
                Team
              </Link>
            </li>
            <li className="nav-link text-white">
              <Link
                className="nav-link text-white"
                aria-current="page"
                to="/services"
              >
                Services
              </Link>
            </li>
            <li className="nav-link text-white">
              <Link
                className="nav-link text-white"
                aria-current="page"
                to="/brands"
              >
                Brands
              </Link>
            </li>
            <li className="nav-link text-white">
              <Link
                className="nav-link text-white"
                aria-current="page"
                to="/partners"
              >
                Partners
              </Link>
            </li>
            <li className="nav-link text-white">
              <Link
                className="nav-link text-white"
                aria-current="page"
                to="/facilities"
              >
                Facilities
              </Link>
            </li>
            <li className="nav-link text-white">
              <Link
                className="nav-link text-white"
                aria-current="page"
                to="contact"
              >
                Contact us
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
