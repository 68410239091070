import React from "react";
import ServicesImg from "../assets/services.png";

function Services() {
  return (
    <div
      className="row mt-5 p-5 d-flex justify-content-center"
      style={{ fontFamily: "Caprasimo" }}
    >
      {/*  */}
      <div className="col-sm-4">
        <div className="row d-flex justify-content-center">
          <div className="row shadow-lg card p-2 m-2">
            <h5 className="text-danger">B2B</h5>
            <p>
              Storage & Distribution for Other Pharmaceutical & Medical
              Companies
            </p>
            <hr />
          </div>

          <div className="row shadow-lg card p-2 m-2">
            <h5 className="text-danger">Local Distribution</h5>
            <p>Distribution for Local products in KSA</p>
            <hr />
          </div>

          <div className="row shadow-lg card p-2 m-2">
            <h5 className="text-danger">
              Global Distribution for International Products
            </h5>
            <p>
              Distribution for International products within KSA & Gulf
              Countries
            </p>
            <hr />
          </div>
        </div>
      </div>
      <div className="col-sm-4">
        <h2
          className="text-primary d-flex justify-content-center"
          style={{ textAlign: "center" }}
        >
          Services
        </h2>

        <div className="row">
          <img
            src={ServicesImg}
            className="img-thumbnail"
            alt="..."
            style={{ width: "100%" }}
          />
        </div>
      </div>
      <div className="col-sm-4">
        <div className="row shadow-lg card p-2 m-2">
          <h5 className="text-danger">Agency Agreements</h5>
          <p>We represent some International Companies in Gulf Countries</p>
          <hr />
        </div>

        <div className="row shadow-lg card p-2 m-2">
          <h5 className="text-danger">Sales & Marketing</h5>
          <p>Sales and Marketing for Others</p>
          <hr />
        </div>

        <div className="row shadow-lg card p-2 m-2">
          <h5 className="text-danger">Regulatory Affairs</h5>
          <p>We Support regulatory affaires for partner's companies</p>
          <hr />
        </div>
      </div>
      {/*  */}
    </div>
  );
}

export default Services;
